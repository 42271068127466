import React from 'react';
import { useTranslation } from 'react-i18next';

const Feature = () => {
  const { t } = useTranslation();

  const features = [
    {
      icon: 'lni lni-vector',
      title: t('features.graphicsDesign'),
      description: t('features.graphicsDescription'),
      delay: '.2s',
    },
    {
      icon: 'lni lni-pallet',
      title: t('features.printDesign'),
      description: t('features.printDescription'),
      delay: '.4s',
    },
    {
      icon: 'lni lni-stats-up',
      title: t('features.businessAnalysis'),
      description: t('features.businessDescription'),
      delay: '.6s',
    },
    {
      icon: 'lni lni-code-alt',
      title: t('features.webDevelopment'),
      description: t('features.webDescription'),
      delay: '.2s',
    },
    {
      icon: 'lni lni-lock',
      title: t('features.bestSecurity'),
      description: t('features.securityDescription'),
      delay: '.4s',
    },
    {
      icon: 'lni lni-code',
      title: t('features.webDesign'),
      description: t('features.designDescription'),
      delay: '.6s',
    },
  ];

  return (
    <section id="feature" className="feature-section feature-style-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
            <div className="section-title text-center mb-60">
              <h3 className="mb-15 wow fadeInUp" data-wow-delay=".2s">
                {t('features.title')}
              </h3>
              <p className="wow fadeInUp" data-wow-delay=".4s">
                {t('features.description')}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {features.map((feature, index) => (
            <div key={index} className="col-lg-4 col-md-6">
              <div
                className="single-feature wow fadeInUp"
                data-wow-delay={feature.delay}
              >
                <div className="icon">
                  <i className={feature.icon}></i>
                  <svg
                    width="110"
                    height="72"
                    viewBox="0 0 110 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M110 54.7589C110 85.0014 85.3757 66.2583 55 66.2583C24.6243 66.2583 0 85.0014 0 54.7589C0 24.5164 24.6243 0 55 0C85.3757 0 110 24.5164 110 54.7589Z"
                      fill="#EBF4FF"
                    />
                  </svg>
                </div>
                <div className="content">
                  <h5>{feature.title}</h5>
                  <p>{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Feature;
