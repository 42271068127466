import React from 'react';
import { useTranslation } from 'react-i18next';

const ClientsLogo = () => {
  const { t } = useTranslation();

  return (
    <section className="clients-logo-section pt-100 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="section-title mb-50">{t('clientsLogo.title')}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="client-logo wow fadeInUp" data-wow-delay=".2s">
              <img
                src="assets/img/clients/desenho2.svg"
                alt="Client Logos"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientsLogo;
