import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');

  const contactDetails = [
    {
      icon: 'lni lni-phone',
      texts: [t('contact.phone1'), t('contact.phone2')],
    },
    {
      icon: 'lni lni-envelope',
      texts: [t('contact.email1')],
    },
    {
      icon: 'lni lni-map-marker',
      texts: [t('contact.address')],
    },
  ];

  // Handle input field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = 'service_ycgigv6'; // Replace with your EmailJS Service ID
    const templateID = 'template_n55uqqh'; // Replace with your EmailJS Template ID
    const publicKey = 'hY93pqjKqkMCwviBb'; // Replace with your EmailJS Public Key

    emailjs
      .send(serviceID, templateID, formData, publicKey)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatusMessage('Mensagem enviada com sucesso!');
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setStatusMessage('Erro ao enviar mensagem.');
      });
  };

  return (
    <section id="contact" className="contact-section contact-style-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-10">
            <div className="section-title text-center mb-50">
              <h3 className="mb-15">{t('contact.title')}</h3>
              <p>{t('contact.description')}</p>
            </div>
          </div>
        </div>
        <div className="row">
          {/* Contact Form Section */}
          <div className="col-lg-8">
            <div className="contact-form-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="single-input">
                      <input
                        type="text"
                        name="from_name"
                        value={formData.from_name}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={t('contact.form.name')}
                        required
                      />
                      <i className="lni lni-user"></i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={t('contact.form.email')}
                        required
                      />
                      <i className="lni lni-envelope"></i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={t('contact.form.phone')}
                      />
                      <i className="lni lni-phone"></i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="single-input">
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={t('contact.form.subject')}
                      />
                      <i className="lni lni-text-format"></i>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="single-input">
                      <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="form-input"
                        placeholder={t('contact.form.message')}
                        rows="6"
                        required
                      ></textarea>
                      <i className="lni lni-comments-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-button">
                      <button type="submit" className="button">
                        <i className="lni lni-telegram-original"></i>{' '}
                        {t('contact.form.submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {statusMessage && <p>{statusMessage}</p>}
            </div>
          </div>

          {/* Contact Details Section */}
          <div className="col-lg-4">
            <div className="left-wrapper">
              <div className="row">
                {contactDetails.map((detail, index) => (
                  <div key={index} className="col-lg-12 col-md-6">
                    <div className="single-item">
                      <div className="icon">
                        <i className={detail.icon}></i>
                      </div>
                      <div className="text">
                        {detail.texts.map((text, i) => (
                          <p key={i}>{text}</p>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
