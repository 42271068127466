import React from 'react';
import { useTranslation } from 'react-i18next';

// Variáveis de ambiente devem ser configuradas no arquivo .env
const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
const whatsappMessage = process.env.REACT_APP_WHATSAPP_MESSAGE;

// Garantir que as variáveis de ambiente estão disponíveis
const whatsappLink = whatsappNumber && whatsappMessage
  ? `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`
  : '#'; // Link inválido se as variáveis não existirem

const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="hero-section-wrapper-5">
      <div
        className="hero-section hero-style-5 img-bg"
        style={{ backgroundImage: "url('assets/img/hero/hero-5/hero-bg.svg')" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="hero-content-wrapper">
                <h2 className="mb-30 wow fadeInUp" data-wow-delay=".2s">
                  {t('hero.title')}
                </h2>
                <p className="mb-30 wow fadeInUp" data-wow-delay=".4s">
                  {t('hero.description')}
                </p>
                {/* Botão do WhatsApp */}
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button button-lg radius-50 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  {t('hero.getStarted')} <i className="lni lni-chevron-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 align-self-end">
              <div className="hero-image wow fadeInUp" data-wow-delay=".5s">
                <img src="assets/img/hero/hero-5/globo.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
