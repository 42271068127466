import React from 'react';

const ScrollToTopButton = () => {
  // Função para rolar suavemente para o topo
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      className="scroll-top"
      onClick={scrollToTop}
      style={{
        display: 'flex',
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000,
        background: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      }}
    >
      <i className="lni lni-chevron-up"></i>
    </button>
  );
};

export default ScrollToTopButton;
