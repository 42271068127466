import React from 'react';
import Hero from './components/Hero';
import Header from './components/Header';
import Feature from './components/Feature';
import About from './components/About';
import Contact from './components/Contact';
import ClientLogo from './components/ClientLogo';
import Footer from './components/Footer';
import './i18n';

const App = () => {
  return (
    <>
      <Header />
      <Hero />
      <Feature />
      <About />
      <Contact />
      <ClientLogo />
      <Footer />
    </>
  );
};

export default App;
