import React from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="preloader">
        <div className="loader">
          <div className="spinner">
            <div className="spinner-container">
              <div className="spinner-rotator">
                <div className="spinner-left">
                  <div className="spinner-circle"></div>
                </div>
                <div className="spinner-right">
                  <div className="spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="home" className="hero-section-wrapper-5">
        <header className="header header-6">
          <div className="navbar-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="index.html">
                      <img src="assets/img/logo/logo_aletheia.svg" alt="Logo" />
                    </a>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent6"
                      aria-controls="navbarSupportedContent6"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                      <span className="toggler-icon"></span>
                    </button>

                    <div
                      className="collapse navbar-collapse sub-menu-bar"
                      id="navbarSupportedContent6"
                    >
                      <ul id="nav6" className="navbar-nav ms-auto">
                        <li className="nav-item">
                          <a className="page-scroll active" href="#home">
                            {t('header.home')}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#feature">
                            {t('header.feature')}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#about">
                            {t('header.about')}
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="page-scroll" href="#contact">
                            {t('header.contact')}
                          </a>
                        </li>
                      </ul>
                      <div className="language-selector ms-3">
                        <button
                          className="btn btn-outline-secondary me-2"
                          onClick={() => changeLanguage('en')}
                        >
                          English
                        </button>
                        <button
                          className="btn btn-outline-secondary me-2"
                          onClick={() => changeLanguage('pt')}
                        >
                          Português
                        </button>
                        <button
                          className="btn btn-outline-secondary me-2"
                          onClick={() => changeLanguage('es')}
                        >
                          Español
                        </button>
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => changeLanguage('zh')}
                        >
                          中文
                        </button>
                      </div>
                    </div>

                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>
      </section>
    </>
  );
};

export default Header;
