import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  const aboutList = [
    {
      text: t('about.list1'),
      delay: '.35s',
    },
    {
      text: t('about.list2'),
      delay: '.4s',
    },
    {
      text: t('about.list3'),
      delay: '.45s',
    },
  ];

  return (
    <section id="about" className="about-section about-style-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6">
            <div className="about-content-wrapper">
              <div className="section-title mb-30">
                <h3 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                  {t('about.title')}
                </h3>
                <p className="wow fadeInUp" data-wow-delay=".3s">
                  {t('about.description')}
                </p>
              </div>
              <ul>
                {aboutList.map((item, index) => (
                  <li key={index} className="wow fadeInUp" data-wow-delay={item.delay}>
                    <i className="lni lni-checkmark-circle"></i>
                    {item.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6">
            <div className="about-image text-lg-right wow fadeInUp" data-wow-delay=".5s">
              <img src="assets/img/about/about-4/satelite.svg" alt={t('about.imageAlt')} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
